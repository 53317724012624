import React from 'react';
import { motion } from 'framer-motion';

function Offers() {
  return (
    <section id="offers" className="py-16 md:py-20 px-4 md:px-8 bg-black">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="max-w-6xl mx-auto"
      >
        <h2 className="text-4xl font-playfair text-gold mb-12 text-center">Special Offers</h2>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="bg-gold/5 border border-gold/30 rounded-sm p-8 text-center max-w-2xl mx-auto"
        >
          <svg 
            className="w-16 h-16 text-gold mx-auto mb-6" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={1.5} 
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <h3 className="text-2xl font-playfair text-gold mb-4">No Active Offers</h3>
          <p className="text-white/80 mb-6">
            We currently don't have any special offers running. Please check back later for exciting deals and promotions!
          </p>
          <button 
            onClick={() => window.location.href = '#rooms'}
            className="bg-gold text-black px-6 py-3 rounded-sm hover:bg-gold/90 transition-colors font-semibold inline-flex items-center space-x-2"
          >
            <span>View Our Rooms</span>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </button>
        </motion.div>
      </motion.div>
    </section>
  );
}

export default Offers; 