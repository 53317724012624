import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import DatePicker from './DatePicker';
import { generateBookingNumber, sendBookingEmails } from '../utils/bookingUtils';

function BookingForm({ isOpen, onClose, currency, convertCurrency }) {
  const [formData, setFormData] = useState({
    roomType: '',
    packageType: '',
    checkIn: '',
    checkOut: '',
    guests: '',
    fullName: '',
    email: '',
    phone: '',
    specialRequests: '',
  });

  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bookingNumber, setBookingNumber] = useState(null);

  const validateStep1 = () => {
    const stepErrors = {};
    if (!formData.roomType) stepErrors.roomType = 'Please select a room type';
    if (!formData.packageType) stepErrors.packageType = 'Please select a package type';
    if (!formData.checkIn) stepErrors.checkIn = 'Please select check-in date';
    if (!formData.checkOut) stepErrors.checkOut = 'Please select check-out date';
    if (!formData.guests) stepErrors.guests = 'Please enter number of guests';

    // Additional date validations
    const today = new Date();
    const checkIn = new Date(formData.checkIn);
    const checkOut = new Date(formData.checkOut);

    if (checkIn < today) stepErrors.checkIn = 'Check-in date cannot be in the past';
    if (checkOut <= checkIn) stepErrors.checkOut = 'Check-out date must be after check-in date';

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const validateStep2 = () => {
    const stepErrors = {};
    if (!formData.fullName) stepErrors.fullName = 'Please enter your full name';
    if (!formData.email) stepErrors.email = 'Please enter your email';
    else if (!/\S+@\S+\.\S+/.test(formData.email)) stepErrors.email = 'Please enter a valid email';
    if (!formData.phone) stepErrors.phone = 'Please enter your phone number';
    else if (!/^\+?[\d\s-]+$/.test(formData.phone)) stepErrors.phone = 'Please enter a valid phone number';

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep1()) {
      setStep(2);
    }
  };

  const handleBack = () => {
    setStep(1);
    setErrors({});
  };

  const calculateTotal = () => {
    const baseRates = {
      'cabana-double': {
        'ro': 15000,
        'bb': 17000,
        'hb': 19000,
        'fb': 22000
      },
      'super-deluxe-double': {
        'ro': 20000,
        'bb': 23000,
        'hb': 27000,
        'fb': 32000
      },
      'super-deluxe-triple': {
        'ro': 23000,
        'bb': 26000,
        'hb': 30000,
        'fb': 35000
      }
    };

    const rate = baseRates[formData.roomType]?.[formData.packageType] || 0;
    const nights = calculateNights();
    return rate * nights;
  };

  const calculateNights = () => {
    if (!formData.checkIn || !formData.checkOut) return 0;
    const checkIn = new Date(formData.checkIn);
    const checkOut = new Date(formData.checkOut);
    const diffTime = Math.abs(checkOut - checkIn);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep2()) return;

    setIsSubmitting(true);
    try {
      const newBookingNumber = generateBookingNumber();
      setBookingNumber(newBookingNumber);

      // Calculate total amount
      const totalAmount = convertCurrency(calculateTotal());

      // Send confirmation emails
      await sendBookingEmails({
        guestEmail: formData.email,
        hotelEmail: 'info@rajinihotels.com',
        bookingNumber: newBookingNumber,
        formData,
        totalAmount,
        currency
      });

      setShowConfirmation(true);
    } catch (error) {
      setErrors({ submit: 'Failed to submit booking. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4 overflow-y-auto backdrop-blur-sm"
        >
          <motion.div 
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-black/95 border border-gold p-6 md:p-8 w-full max-w-md my-8 rounded-sm shadow-xl shadow-gold/20"
          >
            {showConfirmation ? (
              <div className="text-center">
                <div className="text-gold text-5xl mb-4">✓</div>
                <h3 className="text-2xl font-playfair text-gold mb-4">Booking Confirmed!</h3>
                <p className="text-white/80 mb-4">
                  Thank you for choosing Rajini by The Waters. We've sent a confirmation email to {formData.email}.
                </p>
                <div className="bg-gold/10 border border-gold/30 p-4 rounded-sm mb-6">
                  <p className="text-gold font-semibold">Your Booking Number</p>
                  <p className="text-2xl text-white mt-2">{bookingNumber}</p>
                </div>
                <div className="border-t border-gold/30 pt-4 mt-6">
                  <h4 className="text-gold mb-2">Booking Details:</h4>
                  <div className="text-white/80 text-sm space-y-2">
                    <p>Check-in: {new Date(formData.checkIn).toLocaleDateString()}</p>
                    <p>Check-out: {new Date(formData.checkOut).toLocaleDateString()}</p>
                    <p>Total Amount: {convertCurrency(calculateTotal())}</p>
                  </div>
                </div>
                <p className="text-white/60 text-sm mt-6 mb-4">
                  Please check your email for detailed booking information. We'll process your request and send a confirmation shortly.
                </p>
                <button 
                  onClick={onClose}
                  className="w-full bg-gold text-black py-3 rounded-sm hover:bg-gold/90 transition-colors"
                >
                  Close
                </button>
              </div>
            ) : (
              <>
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-2xl font-playfair text-gold">Book Your Stay</h3>
                  <button 
                    onClick={onClose}
                    className="text-gold hover:text-white transition-colors"
                  >
                    ✕
                  </button>
                </div>

                <div className="flex justify-center mb-6">
                  <div className="flex items-center">
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      step === 1 ? 'bg-gold text-black' : 'bg-gold/20 text-gold'
                    }`}>1</div>
                    <div className={`w-16 h-0.5 ${step === 1 ? 'bg-gold/20' : 'bg-gold'}`}></div>
                    <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      step === 2 ? 'bg-gold text-black' : 'bg-gold/20 text-gold'
                    }`}>2</div>
                  </div>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                  {step === 1 ? (
                    <>
                      <div>
                        <label className="block text-gold mb-2">Room Type</label>
                        <select 
                          value={formData.roomType}
                          onChange={(e) => setFormData({...formData, roomType: e.target.value})}
                          className={`w-full bg-black border ${errors.roomType ? 'border-red-500' : 'border-gold'} p-2 text-white rounded-sm`}
                        >
                          <option value="">Select Room Type</option>
                          <option value="cabana-double">Cabana Chalets (Double)</option>
                          <option value="super-deluxe-double">Super Deluxe (Double)</option>
                          <option value="super-deluxe-triple">Super Deluxe (Triple)</option>
                        </select>
                        {errors.roomType && <p className="text-red-500 text-sm mt-1">{errors.roomType}</p>}
                      </div>

                      <div>
                        <label className="block text-gold mb-2">Package Type</label>
                        <select 
                          value={formData.packageType}
                          onChange={(e) => setFormData({...formData, packageType: e.target.value})}
                          className={`w-full bg-black border ${errors.packageType ? 'border-red-500' : 'border-gold'} p-2 text-white rounded-sm`}
                        >
                          <option value="">Select Package Type</option>
                          <option value="ro">Room Only</option>
                          <option value="bb">Bed & Breakfast</option>
                          <option value="hb">Half Board</option>
                          <option value="fb">Full Board</option>
                        </select>
                        {errors.packageType && <p className="text-red-500 text-sm mt-1">{errors.packageType}</p>}
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <DatePicker
                          label="Check-in Date"
                          value={formData.checkIn}
                          onChange={(date) => setFormData({...formData, checkIn: date})}
                          minDate={new Date().toISOString().split('T')[0]}
                          error={errors.checkIn}
                        />
                        <DatePicker
                          label="Check-out Date"
                          value={formData.checkOut}
                          onChange={(date) => setFormData({...formData, checkOut: date})}
                          minDate={formData.checkIn || new Date().toISOString().split('T')[0]}
                          error={errors.checkOut}
                        />
                      </div>

                      <div>
                        <label className="block text-gold mb-2">Number of Guests</label>
                        <input 
                          type="number"
                          value={formData.guests}
                          onChange={(e) => setFormData({...formData, guests: e.target.value})}
                          min="1"
                          max="3"
                          className={`w-full bg-black border ${errors.guests ? 'border-red-500' : 'border-gold'} p-2 text-white rounded-sm`}
                        />
                        {errors.guests && <p className="text-red-500 text-sm mt-1">{errors.guests}</p>}
                      </div>

                      {formData.roomType && formData.packageType && formData.checkIn && formData.checkOut && (
                        <div className="border-t border-gold/30 pt-4 mt-6">
                          <div className="flex justify-between text-gold">
                            <span>Total for {calculateNights()} nights:</span>
                            <span>{convertCurrency(calculateTotal())}</span>
                          </div>
                        </div>
                      )}

                      <button 
                        type="button"
                        onClick={handleNext}
                        className="w-full bg-gold text-black py-3 rounded-sm hover:bg-gold/90 transition-colors"
                      >
                        Next
                      </button>
                    </>
                  ) : (
                    <>
                      <div>
                        <label className="block text-gold mb-2">Full Name</label>
                        <input 
                          type="text"
                          value={formData.fullName}
                          onChange={(e) => setFormData({...formData, fullName: e.target.value})}
                          className={`w-full bg-black border ${errors.fullName ? 'border-red-500' : 'border-gold'} p-2 text-white rounded-sm`}
                        />
                        {errors.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
                      </div>

                      <div>
                        <label className="block text-gold mb-2">Email</label>
                        <input 
                          type="email"
                          value={formData.email}
                          onChange={(e) => setFormData({...formData, email: e.target.value})}
                          className={`w-full bg-black border ${errors.email ? 'border-red-500' : 'border-gold'} p-2 text-white rounded-sm`}
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                      </div>

                      <div>
                        <label className="block text-gold mb-2">Phone</label>
                        <input 
                          type="tel"
                          value={formData.phone}
                          onChange={(e) => setFormData({...formData, phone: e.target.value})}
                          className={`w-full bg-black border ${errors.phone ? 'border-red-500' : 'border-gold'} p-2 text-white rounded-sm`}
                        />
                        {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                      </div>

                      <div>
                        <label className="block text-gold mb-2">Special Requests</label>
                        <textarea
                          value={formData.specialRequests}
                          onChange={(e) => setFormData({...formData, specialRequests: e.target.value})}
                          className="w-full bg-black border border-gold p-2 text-white rounded-sm h-24"
                          placeholder="Any special requests or preferences..."
                        />
                      </div>

                      <div className="flex space-x-4">
                        <button 
                          type="button"
                          onClick={handleBack}
                          className="w-1/2 border border-gold text-gold py-3 rounded-sm hover:bg-gold/10 transition-colors"
                        >
                          Back
                        </button>
                        <button 
                          type="submit"
                          disabled={isSubmitting}
                          className="w-1/2 bg-gold text-black py-3 rounded-sm hover:bg-gold/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {isSubmitting ? 'Processing...' : 'Confirm Booking'}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default BookingForm; 