import emailjs from '@emailjs/browser';

export const generateBookingNumber = () => {
  const prefix = 'RBW'; // Rajini By Waters
  const timestamp = Date.now().toString().slice(-6);
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  return `${prefix}${timestamp}${random}`;
};

export const sendBookingEmails = async (bookingData) => {
  const { guestEmail, hotelEmail, bookingNumber, formData, totalAmount, currency } = bookingData;

  try {
    // Send email to guest
    await emailjs.send(
      'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
      'YOUR_GUEST_TEMPLATE_ID', // Replace with your guest email template ID
      {
        to_email: guestEmail,
        guest_name: formData.fullName,
        booking_number: bookingNumber,
        check_in: new Date(formData.checkIn).toLocaleDateString(),
        check_out: new Date(formData.checkOut).toLocaleDateString(),
        room_type: formData.roomType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        package_type: formData.packageType.toUpperCase(),
        guests: formData.guests,
        total_amount: `${totalAmount} ${currency}`,
        hotel_phone: '+94 76 281 0000',
        hotel_email: 'info@rajinihotels.com'
      }
    );

    // Send email to hotel
    await emailjs.send(
      'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
      'YOUR_HOTEL_TEMPLATE_ID', // Replace with your hotel email template ID
      {
        booking_number: bookingNumber,
        guest_name: formData.fullName,
        guest_email: formData.email,
        guest_phone: formData.phone,
        check_in: new Date(formData.checkIn).toLocaleDateString(),
        check_out: new Date(formData.checkOut).toLocaleDateString(),
        room_type: formData.roomType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        package_type: formData.packageType.toUpperCase(),
        guests: formData.guests,
        total_amount: `${totalAmount} ${currency}`,
        special_requests: formData.specialRequests || 'None'
      }
    );

    return { success: true, bookingNumber };
  } catch (error) {
    console.error('Error sending emails:', error);
    throw new Error('Failed to send confirmation emails');
  }
}; 