export const galleryImages = [
  {
    id: 1,
    url: '/images/gallery/Rajini_by_The_Waters_Image11.jpg',
    title: 'Hotel View',
    category: 'exterior'
  },
  {
    id: 2,
    url: '/images/gallery/Rajini_by_The_Waters_Image12.jpg',
    title: 'Room Interior',
    category: 'rooms'
  },
  {
    id: 3,
    url: '/images/gallery/Rajini_by_The_Waters_Image13.jpg',
    title: 'Pool View',
    category: 'amenities'
  },
  {
    id: 4,
    url: '/images/gallery/Rajini_by_The_Waters_Image14.jpg',
    title: 'Restaurant',
    category: 'dining'
  },
  {
    id: 5,
    url: '/images/gallery/Rajini_by_The_Waters_Image15.jpg',
    title: 'Lake View',
    category: 'surroundings'
  },
  {
    id: 6,
    url: '/images/gallery/Rajini_by_The_Waters_Image16.jpg',
    title: 'Deluxe Room',
    category: 'rooms'
  },
  {
    id: 7,
    url: '/images/gallery/Rajini_by_The_Waters_Image17.jpg',
    title: 'Garden Area',
    category: 'surroundings'
  },
  {
    id: 8,
    url: '/images/gallery/Rajini_by_The_Waters_Image18.jpg',
    title: 'Dining Area',
    category: 'dining'
  },
  {
    id: 9,
    url: '/images/gallery/Rajini_by_The_Waters_Image19.jpg',
    title: 'Spa',
    category: 'amenities'
  },
  {
    id: 10,
    url: '/images/gallery/Rajini_by_The_Waters_Image110.jpg',
    title: 'Hotel Front',
    category: 'exterior'
  },
  {
    id: 11,
    url: '/images/gallery/Rajini_by_The_Waters_Image111.jpg',
    title: 'Suite Room',
    category: 'rooms'
  },
  {
    id: 12,
    url: '/images/gallery/Rajini_by_The_Waters_Image112.jpg',
    title: 'Breakfast Setup',
    category: 'dining'
  },
  {
    id: 13,
    url: '/images/gallery/Rajini_by_The_Waters_Image113.jpg',
    title: 'Swimming Pool',
    category: 'amenities'
  },
  {
    id: 14,
    url: '/images/gallery/Rajini_by_The_Waters_Image114.jpg',
    title: 'Night View',
    category: 'exterior'
  },
  {
    id: 15,
    url: '/images/gallery/Rajini_by_The_Waters_Image115.jpg',
    title: 'Bathroom',
    category: 'rooms'
  },
  {
    id: 16,
    url: '/images/gallery/Rajini_by_The_Waters_Image116.jpg',
    title: 'Bar Area',
    category: 'dining'
  },
  {
    id: 17,
    url: '/images/gallery/Rajini_by_The_Waters_Image117.jpg',
    title: 'Gym',
    category: 'amenities'
  },
  {
    id: 18,
    url: '/images/gallery/Rajini_by_The_Waters_Image118.jpg',
    title: 'Sunset View',
    category: 'surroundings'
  },
  {
    id: 19,
    url: '/images/gallery/Rajini_by_The_Waters_Image119.jpg',
    title: 'Reception',
    category: 'interior'
  },
  {
    id: 20,
    url: '/images/gallery/Rajini_by_The_Waters_Image120.jpg',
    title: 'Garden Path',
    category: 'surroundings'
  }
];

export const galleryCategories = [
  { id: 'all', label: 'All' },
  { id: 'rooms', label: 'Rooms' },
  { id: 'dining', label: 'Dining' },
  { id: 'amenities', label: 'Amenities' },
  { id: 'exterior', label: 'Exterior' },
  { id: 'surroundings', label: 'Surroundings' },
  { id: 'interior', label: 'Interior' }
];

// Keep these for other parts of the site
export const heroImages = [
  '/images/hero/hero-1.jpg',
  '/images/hero/hero-2.jpg',
  '/images/hero/hero-3.jpg'
];

export const roomImages = {
  cabanaChalet: {
    main: '/images/rooms/cabana/main.jpg',
    gallery: [
      '/images/rooms/cabana/gallery-1.jpg',
      '/images/rooms/cabana/gallery-2.jpg',
      '/images/rooms/cabana/gallery-3.jpg'
    ]
  },
  superDeluxe: {
    main: '/images/rooms/deluxe/main.jpg',
    gallery: [
      '/images/rooms/deluxe/gallery-1.jpg',
      '/images/rooms/deluxe/gallery-2.jpg',
      '/images/rooms/deluxe/gallery-3.jpg'
    ]
  }
}; 