import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function Navigation({ isScrolled, setIsBookingOpen, currency, setCurrency, isMobileMenuOpen, setIsMobileMenuOpen }) {
  const currencies = {
    USD: '$',
    LKR: 'රු'
  };

  return (
    <>
      <nav className={`fixed w-full px-8 py-6 flex justify-between items-center z-50 transition-all duration-300 ${
        isScrolled ? 'bg-black shadow-lg' : 'bg-transparent'
      }`}>
        <motion.div 
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="flex items-center"
        >
          <img 
            src="https://rajinihotels.com/wp-content/uploads/2024/10/rajini-hotel-logo-color.png"
            alt="Rajini Hotels"
            className="h-16 w-auto"
          />
        </motion.div>

        <div className="flex items-center space-x-8">
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="hidden md:flex items-center space-x-8"
          >
            <a href="#home" className="text-white hover:text-gold transition-colors">Home</a>
            <a href="#about" className="text-white hover:text-gold transition-colors">About</a>
            <a href="#rooms" className="text-white hover:text-gold transition-colors">Rooms</a>
            <a href="#offers" className="text-white hover:text-gold transition-colors">Offers</a>
            <a href="#gallery" className="text-white hover:text-gold transition-colors">Gallery</a>
            <a href="#contact" className="text-white hover:text-gold transition-colors">Contact</a>
          </motion.div>

          <div className="flex items-center space-x-4">
            <div className="relative group">
              <button className="flex items-center space-x-2 bg-black/50 backdrop-blur-sm border border-gold/30 rounded-sm px-4 py-2 text-gold group-hover:bg-gold/10 transition-all duration-300">
                <span>{currencies[currency]}</span>
                <span>{currency}</span>
                <svg 
                  className="w-4 h-4 transition-transform duration-300 group-hover:rotate-180" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              <div className="absolute top-full right-0 mt-2 w-full invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-300">
                {Object.entries(currencies).map(([code, symbol]) => (
                  <button
                    key={code}
                    onClick={() => setCurrency(code)}
                    className={`w-full flex items-center justify-between px-4 py-2 ${
                      currency === code 
                        ? 'bg-gold/20 text-gold' 
                        : 'bg-black/90 text-white hover:text-gold'
                    } border border-gold/30 backdrop-blur-sm transition-colors duration-300 first:rounded-t-sm last:rounded-b-sm`}
                  >
                    <span>{code}</span>
                    <span>{symbol}</span>
                  </button>
                ))}
              </div>
            </div>

            <button 
              onClick={() => setIsBookingOpen(true)}
              className="hidden md:block bg-gold/20 border border-gold text-gold px-6 py-2 rounded-sm hover:bg-gold hover:text-black transition-all duration-300 backdrop-blur-sm"
            >
              Book Now
            </button>

            {/* Mobile Menu Button */}
            <button 
              onClick={() => setIsMobileMenuOpen(true)}
              className="md:hidden text-gold p-2 hover:bg-gold/10 rounded-sm transition-colors"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/80 backdrop-blur-sm z-40 md:hidden"
              onClick={() => setIsMobileMenuOpen(false)}
            />
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "tween" }}
              className="fixed inset-y-0 right-0 w-[280px] bg-black border-l border-gold/30 z-50 md:hidden overflow-y-auto"
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-8">
                  <img 
                    src="https://rajinihotels.com/wp-content/uploads/2024/10/rajini-hotel-logo-color.png"
                    alt="Rajini Hotels"
                    className="h-12"
                  />
                  <button 
                    onClick={() => setIsMobileMenuOpen(false)}
                    className="text-gold p-2 hover:bg-gold/10 rounded-sm transition-colors"
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <nav className="flex flex-col space-y-1">
                  {['home', 'about', 'rooms', 'offers', 'gallery', 'contact'].map((item) => (
                    <a 
                      key={item}
                      href={`#${item}`}
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="text-white/80 hover:text-gold hover:bg-gold/10 transition-colors capitalize py-3 px-4 rounded-sm"
                    >
                      {item}
                    </a>
                  ))}
                  <div className="pt-4">
                    <button 
                      onClick={() => {
                        setIsBookingOpen(true);
                        setIsMobileMenuOpen(false);
                      }}
                      className="w-full bg-gold text-black py-3 rounded-sm hover:bg-gold/90 transition-colors font-semibold"
                    >
                      Book Now
                    </button>
                  </div>
                </nav>

                {/* Mobile Menu Contact Info */}
                <div className="mt-8 pt-8 border-t border-gold/10">
                  <h4 className="text-gold font-semibold mb-4">Contact Info</h4>
                  <div className="space-y-4 text-sm">
                    <p className="text-white/70">
                      437 Beralihela, Colony 5,<br />
                      82600 Tissamaharama, Sri Lanka
                    </p>
                    <p className="text-white/70">
                      Phone: +94 76 281 0000
                    </p>
                    <p className="text-white/70">
                      Email: info@rajinihotels.com
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

export default Navigation; 