import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Footer from './components/Footer';
import BackToTop from './components/BackToTop';
import BookingForm from './components/BookingForm';
import WhatsAppChat from './components/WhatsAppChat';
import Navigation from './components/Navigation';
import ImageGallery from './components/ImageGallery';
import Offers from './components/Offers';
import { galleryImages, heroImages, roomImages } from './config/images';

function App() {
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [currency, setCurrency] = useState('USD');
  const heroImages = [
    '/images/gallery/Rajini_by_The_Waters_Image11.jpg',
    '/images/gallery/Rajini_by_The_Waters_Image12.jpg',
    '/images/gallery/Rajini_by_The_Waters_Image13.jpg',
    // Add more images as needed
  ];

  const [currentHeroImage, setCurrentHeroImage] = useState(0);

  const testimonials = [
    {
      name: "Sarah Johnson",
      country: "United Kingdom",
      text: "An absolutely stunning hotel with impeccable service. The views are breathtaking!"
    },
    {
      name: "David Chen",
      country: "Singapore",
      text: "Perfect blend of luxury and Sri Lankan hospitality. Will definitely return!"
    },
    {
      name: "Maria Garcia",
      country: "Spain",
      text: "The food was amazing and the staff went above and beyond to make our stay special."
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    
    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHeroImage((prev) => (prev + 1) % heroImages.length);
    }, 5000);
    
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Add error boundary
  useEffect(() => {
    const handleError = (error) => {
      console.error('ResizeObserver error:', error);
    };
    window.addEventListener('error', handleError);
    
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  const convertCurrency = (lkrAmount) => {
    const rate = 0.0031; // 1 LKR = 0.0031 USD (update this rate as needed)
    if (currency === 'USD') {
      return `$${(lkrAmount * rate).toFixed(2)}`;
    }
    return `රු.${lkrAmount.toLocaleString()}/-`;
  };

  return (
    <div className="bg-black min-h-screen text-white">
      <BackToTop />
      <WhatsAppChat />
      
      <Navigation 
        isScrolled={isScrolled}
        setIsBookingOpen={setIsBookingOpen}
        currency={currency}
        setCurrency={setCurrency}
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />

      <section 
        id="home" 
        className="relative h-screen flex items-center justify-center overflow-hidden"
      >
        {heroImages.map((image, index) => (
          <motion.div
            key={image}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentHeroImage ? 1 : 0 }}
            transition={{ duration: 1 }}
            className={`absolute inset-0 ${index === currentHeroImage ? 'z-10' : 'z-0'}`}
          >
            <div 
              className="absolute inset-0 bg-cover bg-center"
              style={{ backgroundImage: `url(${image})` }}
            />
          </motion.div>
        ))}
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70 z-20"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
          className="relative z-30 text-center px-4 max-w-4xl"
        >
          <h1 className="text-4xl md:text-6xl lg:text-7xl font-playfair text-gold mb-4 leading-tight">
            Rajini by<br />The Waters
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-white/90">A Serene Retreat in Thissamaharama, Sri Lanka</p>
          <button 
            onClick={() => setIsBookingOpen(true)}
            className="bg-gold text-black px-8 py-4 text-xl hover:scale-105 transition-transform rounded-sm hover:shadow-lg hover:shadow-gold/20"
          >
            Book Your Stay
          </button>
        </motion.div>
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 animate-bounce">
          <svg className="w-6 h-6 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </div>
      </section>

      <section id="about" className="py-16 md:py-20 px-4 md:px-8 bg-gradient-to-b from-black via-black/95 to-black">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto text-center space-y-8"
        >
          <h2 className="text-4xl font-playfair text-gold mb-8">Welcome to Tranquility</h2>
          <p className="text-lg text-white/80 leading-relaxed">
            Rajini by The Waters is a serene hotel in Sri Lanka, offering a peaceful retreat amidst nature. 
            Nestled by tranquil waters, it provides a perfect escape from the bustle, with elegant rooms, 
            scenic views, and exceptional service for a relaxing stay.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 pt-8">
            <div className="p-6 border border-gold/30 bg-gold/5">
              <h3 className="text-gold font-playfair text-xl mb-3">Scenic Location</h3>
              <p className="text-white/70">Nestled by tranquil waters with breathtaking views</p>
            </div>
            <div className="p-6 border border-gold/30 bg-gold/5">
              <h3 className="text-gold font-playfair text-xl mb-3">Elegant Rooms</h3>
              <p className="text-white/70">Luxurious accommodations with modern amenities</p>
            </div>
            <div className="p-6 border border-gold/30 bg-gold/5">
              <h3 className="text-gold font-playfair text-xl mb-3">Perfect Escape</h3>
              <p className="text-white/70">A peaceful retreat from the urban bustle</p>
            </div>
          </div>
        </motion.div>
      </section>

      <section id="rooms" className="py-16 md:py-20 px-4 md:px-8 bg-black">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-4xl font-playfair text-gold mb-12 text-center"
        >
          Our Accommodations
        </motion.h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-7xl mx-auto">
          {/* Cabana Chalets Card */}
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="border border-gold bg-gold/5 p-8 hover:-translate-y-2 transition-transform duration-300 rounded-sm backdrop-blur-sm"
          >
            <h3 className="text-2xl font-playfair text-gold mb-4">Cabana Chalets</h3>
            <div className="mb-8">
              <h4 className="text-gold text-xl mb-4 flex items-center">
                <span className="mr-2">Package Rates (Double)</span>
                <span className="h-px flex-1 bg-gold/30"></span>
              </h4>
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Room Only</div>
                  <div className="text-2xl font-playfair">{convertCurrency(15000)}</div>
                </div>
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Bed & Breakfast</div>
                  <div className="text-2xl font-playfair">{convertCurrency(17000)}</div>
                </div>
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Half Board</div>
                  <div className="text-2xl font-playfair">{convertCurrency(19000)}</div>
                </div>
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Full Board</div>
                  <div className="text-2xl font-playfair">{convertCurrency(22000)}</div>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h4 className="text-gold text-xl mb-4">Room Amenities</h4>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"/>
                  </svg>
                  <span>Welcome Drink</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                  </svg>
                  <span>A/C Rooms</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"/>
                  </svg>
                  <span>TV</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                  </svg>
                  <span>Pool Access</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                  <span>24/7 Service</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                  </svg>
                  <span>WiFi</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
                  </svg>
                  <span>Room Service</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"/>
                  </svg>
                  <span>Mini Bar</span>
                </div>
              </div>
            </div>
            <button 
              onClick={() => setIsBookingOpen(true)}
              className="w-full bg-gold text-black py-3 rounded-sm hover:scale-105 transition-transform font-semibold"
            >
              Book Now
            </button>
          </motion.div>

          {/* Super Deluxe Card */}
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="border border-gold bg-gold/5 p-8 hover:-translate-y-2 transition-transform duration-300 rounded-sm backdrop-blur-sm"
          >
            <h3 className="text-2xl font-playfair text-gold mb-4">Super Deluxe</h3>
            <div className="mb-8">
              <h4 className="text-gold text-xl mb-4 flex items-center">
                <span className="mr-2">Package Rates</span>
                <span className="h-px flex-1 bg-gold/30"></span>
              </h4>
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Room Only</div>
                  <div className="text-xl font-playfair">Double: {convertCurrency(20000)}</div>
                  <div className="text-lg font-playfair text-white/70">Triple: {convertCurrency(23000)}</div>
                </div>
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Bed & Breakfast</div>
                  <div className="text-xl font-playfair">Double: {convertCurrency(23000)}</div>
                  <div className="text-lg font-playfair text-white/70">Triple: {convertCurrency(26000)}</div>
                </div>
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Half Board</div>
                  <div className="text-xl font-playfair">Double: {convertCurrency(27000)}</div>
                  <div className="text-lg font-playfair text-white/70">Triple: {convertCurrency(30000)}</div>
                </div>
                <div className="p-4 border border-gold/30 rounded-sm">
                  <div className="text-gold mb-2">Full Board</div>
                  <div className="text-xl font-playfair">Double: {convertCurrency(32000)}</div>
                  <div className="text-lg font-playfair text-white/70">Triple: {convertCurrency(35000)}</div>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h4 className="text-gold text-xl mb-4">Room Amenities</h4>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"/>
                  </svg>
                  <span>Welcome Drink</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                  </svg>
                  <span>A/C Rooms</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"/>
                  </svg>
                  <span>TV</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"/>
                  </svg>
                  <span>Pool Access</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                  </svg>
                  <span>Breakfast</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z"/>
                  </svg>
                  <span>Lunch & Dinner</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
                  </svg>
                  <span>Room Service</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                  </svg>
                  <span>Hot Water</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                  </svg>
                  <span>WiFi</span>
                </div>
                <div className="flex items-center space-x-3 text-white/80">
                  <svg className="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"/>
                  </svg>
                  <span>Mini Bar</span>
                </div>
              </div>
            </div>
            <button 
              onClick={() => setIsBookingOpen(true)}
              className="w-full bg-gold text-black py-3 rounded-sm hover:scale-105 transition-transform font-semibold"
            >
              Book Now
            </button>
          </motion.div>
        </div>
      </section>

      <Offers />

      {/* Gallery Section */}
      <section className="py-16 md:py-20 px-4 md:px-8 bg-black">
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-6xl mx-auto"
        >
          <h2 className="text-4xl font-playfair text-gold mb-12 text-center">Gallery</h2>
          <ImageGallery images={galleryImages} />
        </motion.div>
      </section>

      {/* Guest Experiences Section */}
      <section className="py-16 md:py-20 px-4 md:px-8 bg-gradient-to-b from-black to-black/95">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-6xl mx-auto"
        >
          <h2 className="text-4xl font-playfair text-gold mb-12 text-center">Guest Experiences</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="bg-gold/5 border border-gold/30 p-6 rounded-sm"
              >
                <div className="mb-4">
                  {"⭐".repeat(5)}
                </div>
                <p className="text-white/80 italic mb-4">{testimonial.text}</p>
                <div className="text-gold font-semibold">{testimonial.name}</div>
                <div className="text-white/60 text-sm">{testimonial.country}</div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </section>

      {/* Booking Modal */}
      <BookingForm 
        isOpen={isBookingOpen}
        onClose={() => setIsBookingOpen(false)}
        currency={currency}
        convertCurrency={convertCurrency}
      />

      {/* Contact Form Section */}
      <section className="py-16 md:py-20 px-4 md:px-8 bg-black">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="max-w-6xl mx-auto"
        >
          <h2 className="text-4xl font-playfair text-gold mb-12 text-center">Contact Us</h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Contact Information */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <div>
                <h3 className="text-2xl font-playfair text-gold mb-4">Get in Touch</h3>
                <p className="text-white/80 leading-relaxed">
                  We'd love to hear from you. Please fill out the form or contact us using the information below.
                </p>
              </div>
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <svg className="w-6 h-6 text-gold mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <div>
                    <h4 className="text-gold font-semibold mb-1">Address</h4>
                    <p className="text-white/80">437 Beralihela, Colony 5,<br />82600 Tissamaharama,<br />Sri Lanka</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <svg className="w-6 h-6 text-gold mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                  <div>
                    <h4 className="text-gold font-semibold mb-1">Phone</h4>
                    <p className="text-white/80">+94 76 281 0000</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <svg className="w-6 h-6 text-gold mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <div>
                    <h4 className="text-gold font-semibold mb-1">Email</h4>
                    <p className="text-white/80">info@rajinihotels.com</p>
                  </div>
                </div>
              </div>

              {/* Map Section */}
              <div className="pt-6">
                <h4 className="text-gold font-semibold mb-4">Location</h4>
                <div className="w-full h-[300px] border border-gold/30 rounded-sm overflow-hidden">
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.7876087960087!2d81.28734661477226!3d6.283432995456497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae6be6ce7f4c741%3A0x914f66e26c3b7e51!2sTissamaharama!5e0!3m2!1sen!2slk!4v1645000000000!5m2!1sen!2slk"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    className="grayscale hover:grayscale-0 transition-all duration-300"
                  ></iframe>
                </div>
              </div>

              <div className="pt-6">
                <h4 className="text-gold font-semibold mb-4">Follow Us</h4>
                <div className="flex space-x-4">
                  <a href="#" className="text-white/80 hover:text-gold transition-colors">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                    </svg>
                  </a>
                  <a href="#" className="text-white/80 hover:text-gold transition-colors">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                    </svg>
                  </a>
                </div>
              </div>
            </motion.div>

            {/* Contact Form */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-gold/5 border border-gold/30 p-6 rounded-sm"
            >
              <form className="space-y-4">
                <div>
                  <label className="block text-gold mb-2">Full Name</label>
                  <input 
                    type="text" 
                    className="w-full bg-black border border-gold/30 p-3 text-white rounded-sm focus:border-gold transition-colors"
                    placeholder="Your name"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gold mb-2">Email</label>
                  <input 
                    type="email" 
                    className="w-full bg-black border border-gold/30 p-3 text-white rounded-sm focus:border-gold transition-colors"
                    placeholder="your@email.com"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gold mb-2">Phone</label>
                  <input 
                    type="tel" 
                    className="w-full bg-black border border-gold/30 p-3 text-white rounded-sm focus:border-gold transition-colors"
                    placeholder="Your phone number"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gold mb-2">Subject</label>
                  <input 
                    type="text" 
                    className="w-full bg-black border border-gold/30 p-3 text-white rounded-sm focus:border-gold transition-colors"
                    placeholder="How can we help?"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gold mb-2">Message</label>
                  <textarea 
                    className="w-full bg-black border border-gold/30 p-3 text-white rounded-sm focus:border-gold transition-colors min-h-[150px]"
                    placeholder="Your message..."
                    required
                  ></textarea>
                </div>
                <button 
                  type="submit"
                  className="w-full bg-gold text-black py-3 rounded-sm hover:scale-105 transition-transform font-semibold"
                >
                  Send Message
                </button>
              </form>
            </motion.div>
          </div>
        </motion.div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
}

// Add Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error caught:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong. Please refresh the page.</div>;
    }

    return this.props.children;
  }
}

// Wrap the App component with ErrorBoundary
const AppWithErrorBoundary = () => (
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

export default AppWithErrorBoundary;
